/* ====================
 loding
==================== */
.loding{
  position: fixed;
  z-index: 10000;
  background-color: rgba(0,0,0,0.3);
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}
.loding div{
  top: 50%!important;
	left: 50%!important;
	transform: translate(-50%, -50%)!important;
	-webkit-transform: translate(-50%, -50%)!important;
  -ms-transform: translate(-50%, -50%)!important;
  position: fixed;
}
.pdfframe{height: 500px;}

/* ====================
wrapper
==================== */
.wrapper{width: 100%;}
.wrapperInner{width: 1200px;margin: 0 auto;}

/* ====================
 header
==================== */
header{
  width: 1200px;
  margin: 20px auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.header_t{margin-left: 50px; font-weight: bold;}
.header_logo{margin-right: 50px;}
.header_logo h1 img{width:230px;}

/* ====================
 footer
==================== */
.footer__logolist{
  display: flex;
  width: 90%;
  margin: 30px auto;
  align-items: center;
}
.footer__logolist li{margin-right: 10px;}
.footer__logolist li:last-child{margin-right: 0;}
.footer__logolist li a img{width: 100%;}
.footer__inner{
  background-color: #076d92;
  color: #ffffff;
  padding: 20px;
  box-sizing: border-box;
}
.footer__linktab{
  display: flex;
  width: 600px;
  margin: 0 auto 15px;
  justify-content: center;
}
.footer__linktab li a{
  display: inline-block;
  border: 1px solid #ffffff;
  padding: 3px 20px;
  box-sizing: border-box;
  border-radius: 20px;
  font-size: 12px;
}
.footer__linktab li a:hover{
  opacity: 0.8;
  transition: 0.3s;
  color: #ffffff;
  background-color: #1e8db5;
}
.footer__linktab li{margin-right: 10px;}
.footer__linktab li:last-child{margin-right: 0;}
.copyligth{text-align: center;}

/* ====================
 split（main - sidenav）
==================== */
.wrapperInner__split{display: flex;width: 100%;}
.wrapperInner__split--main{width: 80%;}
.splittit{
 color: #076d92;
 font-size: 24px;
 font-weight: bold;
 text-align: left;
 border-bottom: 1px solid #3584a3;
 margin-bottom: 20px;
}
.splitmain__block{margin-bottom: 30px;}
.splitmain__block img{width:100%;}
.splitmain__block--half{display: flex;width: 100%;}

/* ====================
 breadcrumb
==================== */
.breadcrumb{
  text-align: right;
  font-size: 12px;
  margin-bottom: 10px;
}
.breadcrumb a{color: #85dfe8;}

/* ====================
 seachform
==================== */
.seachform{
  border: 1px solid #e5e5e5;
  box-shadow: 3px 2px 8px -5px #000000;
  padding: 20px;
  box-sizing: border-box;
}
.seachform__tit{text-align: center;font-weight: bold;}
.seachform .seachform__inner--inputT{
  border: 1px solid #d9d9d9;
  padding: 5px;
  box-sizing: border-box;
  width: 100px;
}
.shortI{width: 80px;}
.search_start__tit{display: block;}
.search_start__label{margin: 0 5px;}
.seachform__inner{
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin: 30px auto;
}
.seachform__inner--waveline{display: inline-block;margin: 0 20px;}
.seachform__inner--button{
  width: 500px;
  margin: 10px auto 0;
  display: block;
  background-color: #076d92;
  color: #ffffff;
  text-align: center;
  border-radius: 20px;
  border: 0;
  padding: 10px;
}
.iconSeach{margin-right: 10px;}

/* ====================
 seachContents
==================== */
.searchContents{margin-top: 50px;text-align: center;}
.searchContents__tit{
  text-align: center;
  font-size: 18px;
  font-weight: bold;
}
.searchContents__img{margin: 20px auto;}
.searchContents__img img{width:100%;height: auto;}
.searchContents__info{text-align: center;}
.searchContents__info span{
  max-width: 400px;
  overflow-wrap: break-word;
  display: inline-block;
}
.searchContents__info img{max-width:32px; vertical-align: middle;}
.pdf{margin-left: 10px;}
.pdf:hover{opacity: 0.8;transition: 0.3s;}
.nodata{
  text-align: center;
  margin: 50px auto;
  width:100%;
}
.nodata-img{max-width: 540px;}
.nodata-top{width: 100%!important;}
.infocurrent__info--message .nodata{
  float: none!important;
  text-align: center!important;
  margin: 30px auto!important;
}

/* ====================
 pagenation
==================== */
.ant-pagination{margin: 50px auto!important;text-align: center!important;}
.ant-pagination-item-link, .ant-pagination-next{
  border-color: #076d92!important;
  color: #076d92!important;
  border-radius: 50px!important;
}
/* no-active */
.ant-pagination-item{border-radius: 50px!important;border: 1px solid #076d92!important;}
.ant-pagination-item a{color:#076d92!important;}
/* active */
.ant-pagination-item-active{
  border-radius: 50px!important;
  border: 1px solid #076d92!important;
  background-color: #076d92!important;
}
.ant-pagination-item-active a{color:#ffffff!important;}

/* ====================
 add-form
==================== */
.crudform__inner{
  /* display: flex;
  justify-content: left;
  align-items: center; */
  width: 100%;
  margin: 20px auto 0;
}
.edit{
  display: flex;
  justify-content: left;
  align-items: center;
}
.crudform__inner .crudform__inner--inputT{
  border: 1px solid #d9d9d9;
  padding: 5px;
  box-sizing: border-box;
}
.crudform__inner .crudform__inner--inputS{
  border: 1px solid #d9d9d9;
  padding: 5px;
  box-sizing: border-box;
  width: 80px;
}
.crudtit{
  text-align: center;
  font-size: 20px;
  font-weight: bold;
  margin: 50px auto;
}
.formWrap{
  box-shadow: 3px 2px 8px -5px #000000;
  padding: 30px;
  box-sizing: border-box;
}
.formWrap__inner{width:650px;margin: 0 auto;}
.cautionR{color: #ff2925;margin: 10px 0;}
.crudform__innerImg img{max-width: 100%;}
.crudform__innerImg--input{display: flex; width: 100%;}
.crudform__innerImg--input p{
  border: 1px solid #000000;
  width: 70%;
  padding: 10px;
  box-sizing: border-box;
}
.crudform__innerImg--input label{width: 30%;}
.crudblock--tit{
  display: block;
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 10px;
}
.crudform__innerImg figure{text-align: center;margin: 20px auto;}
.crudblock{margin-top: 50px;}
.crudblockSingleI{
  border: 1px solid #000000;
  padding: 10px;
  box-sizing: border-box;
  width: 100%;
}
.ant-modal-confirm-btns{
  width: 80%!important;
  margin: 30px auto 0!important;
  float: none!important;
}
.ant-modal-confirm-btns button:first-child{display: none!important;}
.ant-modal-confirm-btns .ant-btn-primary{
  width: 100%!important;
  border-radius: 50px!important;
  color: #076d92!important;
  color: #ffffff!important;
}
.ant-modal-confirm-body svg{display: none!important;}
.ant-modal-confirm-content{text-align: center;}

/* ====================
 button-double
==================== */
.buttonDouble{text-align: center;margin: 50px auto;}
.buttonDouble__submit{
  display: inline-block;
  background-color: #076d92;
  color: #ffffff;
  width: 200px;
  text-align: center;
  border-radius: 50px;
  padding: 10px;
  margin-right: 50px;
}
.buttonDouble__submit:hover{transition: 0.3s;background-color: #85dfe8;}
.buttonDouble__cancel{
  display: inline-block;
  background-color: #858585;
  color: #ffffff;
  width: 200px;
  text-align: center;
  border-radius: 50px;
  padding: 10px;
}
.buttonDouble__cancel:hover{
  transition: 0.3s;
  background-color: #85dfe8;
  cursor : pointer!important;
  appearance: none!important;
}

/* ====================
 error-massage（validation）
==================== */
.errMassage{
  display: inline-block;
  background-color: #ef0000;
  padding: 5px;
  box-sizing: border-box;
  color: #ffffff;
  font-weight: bold;
  letter-spacing: 1px;
}
.errMassage{
  display: inline-block;
  background-color: #ef0000;
  padding: 5px 20px;
  box-sizing: border-box;
  color: #ffffff;
  font-weight: bold;
  letter-spacing: 1px;
  margin-top: 20px;
}

/* ====================
edit
==================== */
.noedit{
  padding: 5px 30px;
  box-sizing: border-box;
  font-weight: bold;
  background-color: #d7d7d7;
}
.imgCaution{
  text-align: right;
  margin-top: 15px;
  font-weight: bold;
}
.standardsize{margin-top: 10px;font-weight: bold;}

/* ====================
disabled
==================== */
.disabled{background-color: #bfbfbf;border: none;}
.disablDay{
  background-color: #bfbfbf;
  border: none;
  padding: 5px;
  box-sizing: border-box;
  width: 100px;
}
/* ====================
picker
==================== */
.picker{
  border: 1px solid #000000;
  padding: 5px 10px;
  box-sizing: border-box;
}