ul{
  margin: 0;
  padding: 0;
  list-style-type: none;
}
p{margin: 0;}
.wrap-tab{overflow: hidden;}
.tab-radio{display: none;}
.list-tab-label{
  display: flex;
  margin: 0 -2px;
  border-bottom: 3px solid #5bbee5;
}
.list-tab-label > li{
  margin: 0 2px;
  width: 100%;
  text-align: center;
}
.tab-label{
  display: block;
  padding: 0.5em 1em;
  color: #fff;
  background: #ccc;
  box-sizing: border-box;
  cursor: pointer;
}
#tab-radio1:checked ~ .list-tab-label #tab-label1,
#tab-radio2:checked ~ .list-tab-label #tab-label2,
#tab-radio3:checked ~ .list-tab-label #tab-label3,
#tab-radio4:checked ~ .list-tab-label #tab-label4{background: #5bbee5;}
.tab-content{display: none;padding: 1em;}
#tab-radio1:checked ~ .wrap-tab-content #tab-content1,
#tab-radio2:checked ~ .wrap-tab-content #tab-content2,
#tab-radio3:checked ~ .wrap-tab-content #tab-content3,
#tab-radio4:checked ~ .wrap-tab-content #tab-content4{display: block;}

/* ====================
 detail
==================== */
.imgLabel {
  padding: 10px 40px;
  color: #ffffff;
  background-color: #076d92;
  cursor: pointer;
  display: inline-block;
}
.imgLabel:hover{transition: 0.3s;background-color: #85dfe8;}
.imgInput{display: none;}
.tab-group{display: flex;justify-content: center;}
.tab{
  flex-grow: 1;
  padding:5px;
  list-style:none;
  border:solid 1px #CCC;
  text-align:center;
  cursor:pointer;
}
.panel-group{
  height:100px;
  border:solid 1px #CCC;
  border-top:none;
  background:#eee;
}
.panel{display:none;}
.tab.is-active{
  background:#F00;
  color:#FFF;
  transition: all 0.2s ease-out;
}
.panel.is-show{display:block;}