/* ====================
 header
==================== */
.topnavvisual{display: flex;width: 100%;}
.side{width: 20%;}

.sidenav li a{
  display: block;
  background: rgb(23,72,97);
  background: linear-gradient(0deg, rgba(23,72,97,1) 45%, rgba(92,155,189,1) 100%);
  text-align: center;
  padding: 17px;
  color: #ffffff;
  box-sizing: border-box;
  width:90%;
  transition: width 0.2s;
}
.sidenav li{margin-bottom: 1px;}
.sidenav li:nth-last-child(){margin-bottom: 0}
.sidenav li a:hover{width:95%;background: #49C2D2;}

/* login後menu */
.sidenav .AuthMenu a{
  display: block;
  background: rgb(23,72,97);
  background: linear-gradient(0deg,#176143 45%,#5cbd67);
  text-align: center;
  padding: 17px;
  color: #ffffff;
  box-sizing: border-box;
  width:90%;
  transition: width 0.2s;
}
.sidenav .AuthMenu{margin-bottom: 1px;}
.sidenav .AuthMenu:nth-last-child(){margin-bottom: 0}
.sidenav .AuthMenu a:hover{width:95%;background: #49d260;}

.topnavvisual__visual{width:80%;position: relative;}
.topnavvisual__visual h2{
  position: absolute;
  top: 10px;
  right: 40px;
  text-align: right;
  color: #076d92;
  font-weight: bold;
}
.topnavvisual__visual img{width:100%;}
.arrow{
  position: relative;
  display: inline-block;
  padding: 0 0 0 16px;
  color: #000;
  vertical-align: middle;
  text-decoration: none;
  font-size: 15px;
}
.arrow::before,
.arrow::after{
  position: absolute;
  top: 0;
  bottom: 0;
  right: 10px;
  margin: auto;
  content: "";
  vertical-align: middle;
}
.circle::before{
  width: 15px;
  height: 15px;
  -webkit-border-radius: 50%;
  border-radius: 50%;
  background-color: #ffffff;
}
.circle::after{
  box-sizing: border-box;
  width: 5x;
  height: 5px;
  border: 5px solid transparent;
  border-left: 5px solid #174861;
}
.circle:hover::after{border-left: 5px solid #49C2D2 !important;}
.AuthMenu .circle::after{
  box-sizing: border-box;
  width: 5x;
  height: 5px;
  border: 5px solid transparent;
  border-left: 5px solid #193c1a;
}
.AuthMenu .circle:hover::after{border-left: 5px solid #176118 !important;}
/* ====================
 info + current
==================== */
.infocurrent{
  display: flex;
  width: 100%;
  margin: 20px auto;
}
.infocurrent__info{width:40%;margin-right: 20px;}
.infocurrent__current{width:60%;}
.infocurrent__current p{text-align: center;}
.toptit__en{color: #4f92ad;}
.toptit__main{font-size: 20px;font-weight: bold;}
.toptit{text-align: center;margin: 20px auto 0;}
.toptit p{text-align: center;}
.infocurrent__info--message{
  height: 200px;
  overflow: auto;
  margin-top: 30px;
}
.infocurrent__info--messageImg{
  height: 400px;
  overflow: auto;
  margin-top: 20px;
}
.topInnerImg{margin: 20px auto;}
.infocurrent__info--message dt, .infocurrent__info--messageImg dt{float: left; font-weight: normal;}
.infocurrent__info--message dd, .infocurrent__info--messageImg dd{float: right;}
.infocurrent__info--message span, .infocurrent__info--messageImg span{color: #ff2724;margin-left: 10px;}
.infocurrent__current figure img{width:100%;}
.infocurrent__info--message .infoInner, .infocurrent__info--messageImg .infoInner{
  border-top: 1px solid #dddddd;
  padding: 10px;
  box-sizing: border-box;
  overflow: hidden;
  font-size: 13px;
}
.infoInner dt{width:30%!important;}
.infoInner dd{width:70%!important;}
.greeting{margin: 50px auto 100px;}
.greeting .greeting_txt{
  line-height: 40px;
  margin-top: 30px;
  border: 1px solid #000;
  padding: 20px 10px;
  box-sizing: border-box;
  width: 900px;
  margin: 20px auto 0;
}
.greeting .greeting_txt p{width: 690px;margin: 0 auto;}