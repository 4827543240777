/* ====================
 overviewPage
==================== */
.businesstable{
  width: 100%;
  border: 1px solid #f3f6f7;
  border-collapse: collapse;
}
.businesstable tr td{
  border: 1px solid #f3f6f7;
  padding: 5px;
  box-sizing: border-box;
  vertical-align: middle;
}
.businesstable__tit th{
  background-color: #dbeeef;
  font-weight: bold;
  text-align: center;
  padding: 5px;
  box-sizing: border-box;
  border: 1px solid #eeefef;
}
.businesstable__subtit{
  background-color: #eeefef;
  font-weight: bold;
  text-align: center;
}
.businesstable__subT tr td:last-child{text-align: right;}
.businesstable__subT tr td{
  border: 0;
  border-right: 1px solid #eeefef!important;
  border-bottom: 1px solid #eeefef!important;
}
.businesstable__subT tr td:last-child{border-right:0!important;}
.businesstable__subT tr:last-child td{border-bottom: 0!important;}
.businesstable__subT{width:100%;}
.businesstable .paddingN{padding: 0!important;}
.splitmain__block--half p{width: 310px;line-height: 24px;}
.splitmain__block--half figure{width: 650px;}
.businesstable__subT small{font-size: 10px;vertical-align: top;}
.splitmain__block img{max-width: 100%!important;}

/* ====================
 status-uploadPage
==================== */
.previewImg {width:100%;height: auto;}

/* ====================
 processPage
==================== */
.processimg{margin-bottom: 200px;}

/* ====================
 guidePage
==================== */
.guidelayout{
  width: 100%;
  margin-bottom: 50px;
  overflow: hidden;
  display: flex;
}
.guidelayout dl{width:50%;}
.guidelayout dl dt:last-child{margin-top: 20px;}
.guidelayout figure{width:50%;}
.guidelayout figure img{width:100%;}


/* ====================
 info-uploadPage
==================== */
.crudblock__half{display: flex;}
.crudblock__half .crudblock__halfBlk{margin-right:40px;}
.crudblock__half .crudblock__halfBlk:last-child{margin-right:0;}
.crudblock__half .crudblock__halfBlk input{margin:0 10px 5px 0;}
/* ====================
 info
==================== */
.infoWrapper .infoBlk{
  display: flex;
  width: 100%;
  align-items: center;
  padding: 10px;
  box-sizing: border-box;
  border-bottom: 1px solid #c1c1c1;
}
.infoWrapper .infoBlk:last-child{border-bottom:0;}
.infoBlk .infoBlk_cts{
  width:70%;
  display: flex;
  align-items: center;
}
.infoBlk .infoBlk_cts span{
  width: 20%;
}
.infoBlk .infoBlk_cts p{
  width: 80%;
  overflow-wrap: break-word;
}
.infoBlk .infoBlk_btn{
  width:30%;
  display: flex;
}
.infoBlk .infoBlk_btn a{
  width: 50%;
  text-align: center;
  padding: 10px;
  background-color: #076d92;
  color: #ffffff;
}
.infoBlk .infoBlk_btn a:hover{
  transition: 0.3s;background-color: #85dfe8;
}
.infoBlk .infoBlk_btn button{
  width: 50%;
  text-align: center;
  padding: 10px;
  background-color: #858585;
  color: #ffffff;
}
.infoBlk .infoBlk_btn button:hover{transition: 0.3s;background-color: #85dfe8;}


.infoBlk .infoBlk_btn a{margin-right: 10px;}



/* ====================
 sideMenu-Union
==================== */

.unionLink{margin-top: 20px;}
.unionLink a{
  border: 1px solid #076d92;
  border-radius: 5px;
  font-size: 12px;
  width: 90%;
  padding: 10px;
  height: 55px;
  line-height: 35px;
  font-size: 20px;
  text-align: center;
  color:#076d92;
  margin-right: 20px;
  margin-bottom: 20px;
  line-height: 16px!important;
  font-size: 12px!important;
  text-align: left!important;
}
.unionLink a:hover{
  opacity: 0.8;
  transition: 0.3s;
  background-color: #49C2D2;
  color: #ffffff;
}
.linkarrow{
  position: relative;
  display: inline-block;
  padding: 0 0 0 16px;
  color: #000;
  vertical-align: middle;
  text-decoration: none;
  font-size: 15px;
}
.linkarrow::before,
.linkarrow::after{
  position: absolute;
  top: 0;
  bottom: 0;
  right: 10px;
  margin: auto;
  content: "";
  vertical-align: middle;
}
.linkcircle::before{
  width: 15px;
  height: 15px;
  -webkit-border-radius: 50%;
  border-radius: 50%;
  background-color: #076d92;
}
.linkcircle::after{
  box-sizing: border-box;
  width: 9x;
  height: 5px;
  border: 4px solid transparent;
  border-left: 5px solid #ffffff;
}
.linkcircle:hover::after{border-left: 5px solid #49C2D2 !important;}

/* ====================
 tab（components）
==================== */
.ant-tabs-nav-list{width:100%!important;}
.ant-tabs-tab{
  text-align: center!important;
  width: 50%!important;
  display: inline-block;
  background-color: #e9e9e9!important;
}
.ant-tabs-tab-active{background-color: #076d92!important;text-align: center!important;}
.ant-tabs-tab-active .ant-tabs-tab-btn{color: #ffffff!important;}
.ant-tabs-ink-bar{width: 0!important;}
.ant-tabs-tab+.ant-tabs-tab{margin:0!important;}
.ant-tabs-top>.ant-tabs-nav:before{border-bottom: 2px solid #076d92!important;z-index: 1!important;}
.ant-tabs-top>.ant-tabs-nav{margin: 0!important;}
.ant-tabs-content-holder{
  padding: 50px!important;
  box-sizing: border-box!important;
  border: 1px solid #076d92!important;
}
.tabcontents__blk{
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  background-color: whitesmoke;
  padding: 25px;
  box-sizing: border-box;
  margin-bottom: 25px;
}
.tabcontents__blk--contents{width:100%;}
.tabcontents__blk--contents p{
  font-size: 18px;
  font-weight: bold;
  text-align: center;
}
.tabcontents__blk--contents span{
  font-size: 14px;
  text-align: center;
  display: block;
  width: 400px;
  overflow-wrap: break-word;
}
.tabcontents__blk--contents figure{margin:20px auto;}
.tabcontents__blk--contents figure img{width:100%; height: auto;}
.tabcontents__blk--btn{width:100%;text-align: center;}
.tabcontents__blk--btn a{
  display: block;
  background-color: #076d92;
  color: #ffffff;
  width: 250px;
  text-align: center;
  border-radius: 50px;
  padding: 10px;
  margin: 0 auto 50px;
}
.tabcontents__blk--btn a:hover{transition: 0.3s;background-color: #85dfe8;}
.tabcontents__blk--btn button{
  display: block;
  background-color: #858585;
  color: #ffffff;
  width: 250px;
  text-align: center;
  border-radius: 50px;
  padding: 10px;
  margin: 0 auto;
}
.tabcontents__blk--btn button:hover{transition: 0.3s;background-color: #85dfe8;}
.guidemap{height:600px}